<template lang="">
    <main class="site-content">
        <PreloaderVue :show="showPreloader"/>
        <slot></slot>
    </main>
</template>
<script>
import { mapGetters } from "vuex";

import PreloaderVue from './PreloaderVue.vue';
export default {
    components: {
        PreloaderVue
    },
    data() {
    return {
      showPreloader: {}
    }
  },
  watch: {
    $route(from) {
      this.anchorHashCheck();

      if(from && from.name != 'vancncy') {
          this.showPreloader = null
      }

    },

    getPage(val){
      if(val) {

        this.showPreloader = this.getPage

        this.addMetaData()
      }
      
    },

    getSingleShop(val){
        
      if(val) {
        this.showPreloader = this.getSingleShop
      }
    },
    getSingleVacancy(val) {
      if(val) {
        this.addMetaData()
      }
    }
  },
  computed: {
        ...mapGetters(["getPage", "getSingleShop", "getSingleVacancy"]),
    },
    mounted() {
      this.anchorHashCheck();
      
  },
  methods: {
    anchorHashCheck() {
      let url = window.location.hash;      

      const intervalId = setInterval(function(){
        if(!window.location.hash || window.location.hash == "") return

        let el = document.getElementById(window.location.hash.slice(1));
        
        if (el) { 
          clearInterval(intervalId); 

          if (url) {
            let el = document.getElementById(window.location.hash.slice(1));
            
            if (el) {
              const elTop = el.getBoundingClientRect().top + window.scrollY;
              window.scrollTo({top: elTop - 50, behavior: "smooth"});
            }
          }
        }
      }, 300);
    },
    addMetaData() {
      if(this.$route.name == 'home') {
          document.title = this.getPage.seo_yoast.title

          let description =  document.querySelector('meta[name="description"]');
          if(description) description.setAttribute("content", this.getPage.seo_yoast.description);

          let ogDescriptionMeta = document.querySelector('meta[property="og:description"]')
          if(ogDescriptionMeta) ogDescriptionMeta.setAttribute("content", this.getPage.seo_yoast.opengraph_description);

          let ogTitleMeta = document.querySelector('meta[property="og:title"]');
          if(ogTitleMeta) ogTitleMeta.setAttribute("content", this.getPage.seo_yoast.opengraph_title);

          let canonicalLink = document.querySelector('link[rel="canonical"]');
          if(canonicalLink) canonicalLink.setAttribute("href", window.location.origin);

        } else {
          const pageContent = this.getPage ? this.getPage[0] : this.getSingleVacancy

          const seo = pageContent.yoast_head_json 
          const pageTitle = seo?.og_title ? seo.og_title : pageContent?.title?.rendered 
          const pageDescription = seo?.og_description ? seo.og_description : ''
          const pageUrl = seo?.og_url ? seo.og_url : window.location.href 

          document.title = pageTitle

          let description =  document.querySelector('meta[name="description"]');
          if(description) description.setAttribute("content", pageDescription);

          let ogDescriptionMeta = document.querySelector('meta[property="og:description"]')
          if(ogDescriptionMeta) ogDescriptionMeta.setAttribute("content", pageDescription);

          let ogTitleMeta = document.querySelector('meta[property="og:title"]');
          if(ogTitleMeta) ogTitleMeta.setAttribute("content", pageTitle);

          let canonicalLink = document.querySelector('link[rel="canonical"]');
          if(canonicalLink) canonicalLink.setAttribute("href", pageUrl);
        }
      }
  },
}
</script>
<style lang="">
    
</style>