
<template>
  <div class="site">
      <SiteHeader/>
        <GlobalWraper>
          <router-view/>
      </GlobalWraper>
    <SiteFooter/>
  </div>
</template>

<script>
  import SiteHeader from './components/global/HeaderSite.vue'
  import SiteFooter from './components/global/FooterSite.vue'

  import GlobalWraper from './components/global/GlobalWraper.vue'
export default {
  components: {
    SiteHeader,
    SiteFooter,
    GlobalWraper
  }
}
</script>

<style lang="scss" scope>
  @import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
  @import url('https://fonts.cdnfonts.com/css/verveine');
  @import "./assets/scss/styles.scss";
</style>
