<template>
  <div class="post">
    <div v-if="post">
      <div v-if="post.content" v-html="post.content.rendered"></div>
   </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapGetters, mapActions } from 'vuex'

@Options({
  components: {
  },

  computed: {
    ...mapGetters([
    'getSinglePost'
    ]),
  },

  methods: {
    ...mapActions([
      'fetchSinglePost'
    ])
  }

})

export default class PageView extends Vue {
    $store!: any;

    $route!: any;

    post:any = []

    fetchSinglePost!: (pageSlug: string) => Promise<void>
    getSinglePost!: any

   mounted() {

    const pageSlug = this.$route.params?.postSlug;
    // Use the mapped getter and action.
    this.fetchSinglePost(pageSlug).then(() => {
      this.post = this.getSinglePost[0]
      return
    })
  }
}

</script>