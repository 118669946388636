<template>
    <div class="footer-button-block">
        <div class="row">
            <router-link
          :to="link(getSettings.blyz_add_group_links.blyz_link_papper)"
          v-if="getSettings && getSettings.blyz_add_group_links"
          class="btn green"
        >
          АКЦІЙНІ ПРОПОЗИЦІЇ
        </router-link>

         <router-link
          :to="link(getSettings.blyz_add_group_links.blyz_link_shops)"
          v-if="getSettings && getSettings.blyz_add_group_links"
          class="btn white"
        >
          {{ getSettings.blyz_add_group_links.blyz_text_btn_shops }}
        </router-link>
        </div>
    </div>
</template>
<script>

import { mapGetters } from "vuex";

export default {
     computed: {
        ...mapGetters(["getSettings"]),
    },
    methods: {
        link(url) {
            const baseUrl = window.location.origin + "/blyzenko/";

            const link = new URL(url, baseUrl);
            if (link.pathname) {
                return link.pathname;
            }

            return link;
        },
    },
}
</script>
<style lang="">
    
</style>