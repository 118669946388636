<template lang="">
    <div v-if="page && page.content" v-html="page.content.rendered"></div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { gsapMixin } from '../mixins/gsapMixin';

export default {
    mixins: [gsapMixin],
    data() {
    return {
      page: null,
      pageId: null,
    };
  },
  computed: {
    ...mapGetters(["getPage", "getSettings"]),
  },
  mounted() {
    this.loadPageContent();
  },
  watch: {
    getSettings() {
        this.loadPageContent();
    }
  },
  methods: {
    ...mapActions(["fetchPage"]),

    loadPageContent() {
        if(!this.getSettings['404_page']) {
            return
        }

        const link = String(this.getSettings['404_page'])
        const baseUrl = window.location.origin
        const url = new URL(link, baseUrl)

        const slug = url.pathname

      this.fetchPage(slug)
        .then(() => {

          this.page = this.getPage[0];
          this.pageId = this.page.id;
          this.loadCssFile('custom-frontend.min')
          this.loadCssFile('global')
          this.loadCssFile('post-2992');
          this.loadCssFile();

        })
        .catch((error) => {
          console.error(error);
        });
    },

    loadCssFile(cssName) {
      const stylesheetId = cssName ? `elementor-${cssName}` : `elementor-post-${this.pageId}-css`;
  
      if (document.getElementById(stylesheetId)) return;

      const domainElement =
        document.head && document.head.querySelector('[property="og:url"]');
      const domain = domainElement
        ? domainElement.content
        : "https://blyz.wyr.com.ua";

      const url = cssName ? `${domain}/wp-content/uploads/elementor/css/${cssName}.css` : `${domain}/wp-content/uploads/elementor/css/post-${this.pageId}.css`;
     

      return new Promise((resolve, reject) => {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = url;
        link.id = stylesheetId;
        link.onload = () => resolve();
        link.onerror = () =>
          reject(new Error(`Failed to load CSS file: ${url}`));
        document.head.appendChild(link);
      }).catch((reject) => {
        if (reject) {
          const stylesheetId = cssName ? `elementor-${cssName}` : `elementor-post-${this.pageId}-css`;
          const stylesheetLink = document.getElementById(stylesheetId);

          if (stylesheetLink) {
            document.head.removeChild(stylesheetLink);
          }
        }
      });
    },
  }
}
</script>
<style lang="">
    
</style>