<template lang="">
    <div class="running-time-wraper" v-if="getSettings">
      
          <div :class="`running-time ${color ? color : ''} ${postionLine? postiton : ''}`">
            <router-link :to="pagePath()"  class="running-time-link">

              <div class="logo-wrap running-time-item">
                <span class="img">
                  <picture class="mini-logo" v-if="getSettings && getSettings.blyz_logo_mini">
                    <img :src="getSettings.blyz_logo_mini.sizes.medium" :alt="getSettings.blyz_logo_mini.title" width="128" height="128">
                  </picture>
                </span>
              </div>
              <div class="running-time-item">
                <span class="running-time-text" v-if="itemText_1">{{itemText_1}}</span>
                <span class="running-time-text" v-else>Вигідні</span>
              </div>
              <div class="logo-wrap running-time-item">
                <span class="img">
                  <picture class="mini-logo" v-if="getSettings && getSettings.blyz_logo_mini">
                    <img :src="getSettings.blyz_logo_mini.sizes.medium" :alt="getSettings.blyz_logo_mini.title" width="128" height="128">
                  </picture>
                </span>
              </div>
              <div class="running-time-item">
                <span class="running-time-text"  :class="{'border': border}" v-if="itemText_2">{{itemText_2}}</span>
                <span class="running-time-text"  :class="{'border': border}" v-else>Тицьніть тут</span>
              </div>
              <div class="logo-wrap running-time-item">
                <span class="img">
                  <picture class="mini-logo" v-if="getSettings && getSettings.blyz_logo_mini">
                    <img :src="getSettings.blyz_logo_mini.sizes.medium" :alt="getSettings.blyz_logo_mini.title" width="128" height="128">
                  </picture>
                </span>
              </div>
              <div class="running-time-item">
                <span class="running-time-text" v-if="itemText_3">{{itemText_3}}</span>
                <span class="running-time-text" v-else>Акції</span>
              </div>
              <div class="logo-wrap running-time-item">
                <span class="img">
                  <picture class="mini-logo" v-if="getSettings && getSettings.blyz_logo_mini">
                    <img :src="getSettings.blyz_logo_mini.sizes.medium" :alt="getSettings.blyz_logo_mini.title" width="128" height="128">
                  </picture>
                </span>
              </div>
              <div class="running-time-item">
                  <span class="running-time-text" v-if="itemText_1">{{itemText_1}}</span>
                  <span class="running-time-text" v-else>Вигідні</span>
              </div>
              <div class="logo-wrap running-time-item">
                <span class="img">
                  <picture class="mini-logo" v-if="getSettings && getSettings.blyz_logo_mini">
                    <img :src="getSettings.blyz_logo_mini.sizes.medium" :alt="getSettings.blyz_logo_mini.title" width="128" height="128">
                  </picture>
                </span>
              </div>
              <div class="running-time-item">
                  <span class="running-time-text"  :class="{'border': border}" v-if="itemText_2">{{itemText_2}}</span>
                  <span class="running-time-text" :class="{'border': border}" v-else>Тицьніть тут</span>
              </div>
              <div class="logo-wrap running-time-item">
                <span class="img">
                  <picture class="mini-logo" v-if="getSettings && getSettings.blyz_logo_mini">
                    <img :src="getSettings.blyz_logo_mini.sizes.medium" :alt="getSettings.blyz_logo_mini.title" width="128" height="128">
                  </picture>
                </span>
              </div>
              <div class="running-time-item">
                  <span class="running-time-text" v-if="itemText_3">{{itemText_3}}</span>
                  <span class="running-time-text" v-else>Акції</span>
              </div>
            </router-link>
          </div>
        </div>
</template>
<script>

import { mapGetters } from "vuex";
export default {
    props: {
        color: String,
        postionLine: String,
        border: Boolean,
        ancor: String,
        itemText_1: String,
        itemText_2: String,
        itemText_3: String
    },
    
    computed: {
    ...mapGetters(["getSettings"]),
  },
  methods: {
    pagePath(){
      if(this.getSettings) {

        const baseUrl = window.location.origin

        const url = this.getSettings.blyz_add_group_links && this.getSettings.blyz_add_group_links.sales_link ? this.getSettings.blyz_add_group_links.sales_link : null

        const link = !url ? `${window.location.herf}#` : new URL(url, baseUrl);


        if (link.pathname) {
          return this.ancor ? link.pathname + this.ancor : link.pathname;
        }

        return !url ? `${window.location.herf}#` : url

      }
    }
  },
}
</script>
<style lang="">
    
</style>