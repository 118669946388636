import apiClient from '@/axios';

export const singePost = {
    state: () => ({
        post: [],
        errors: []
    }),

    actions: {
        async fetchSinglePost({ commit }:any, slug:string) {
            try {
                const response = await apiClient.get(`posts?slug=${slug}`);

                commit('setPost', response)
                commit('setPostError', null)

            }  catch (error) {
                commit('setPostError', error)
            }        
              
          },
    },
    mutations: {
        setPost(state:any, post:any) {
            if(!post) {
                state.post = []
                return
            }
            state.post = post.data
        },

        setPostError(state:any, error:any) {
            if(!error) {
                state.errors = []
            }

            state.errors = error
        }
    },
    getters: {
        getSinglePost(state:any) {
            return state.post
        }
    }
}
