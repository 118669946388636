import axios from 'axios';

const domainElement = document.head && document.head.querySelector('[property="og:url"]');
let domain = domainElement ? (domainElement as HTMLMetaElement).content : '';

if(!domain) {
  domain = 'https://blyzenko.ua'
}

const baseUrl = domain + process.env.VUE_APP_API_URL;

const apiClient = axios.create({
    baseURL: baseUrl, // Replace with your API base URL
    headers: {
      // You can add common headers here
      'Content-Type': 'application/json',
    },
  });
  
  export default apiClient;