<template>
  <nav
    class="main-nav"
  >
    <button title="Меню" class="nav-toggle" :class="{active: showNav}" @click.prevent="activeMenu">
        <span class="text">Меню</span> 

        <div class="burger">
            <span></span>
            <span></span>
        </div>
    </button>
    <Transition name="fade-down">
    <div class="main-nav-content" v-show="showNav" >
        
            <div class="nav-bg nav-bg-left" :class="{active: leftBgActive}"></div>
        <div class="nav-bg nav-bg-right" :class="{active: rightBgActive}"></div>
       
      <Transition name="fade-down">
            <div class="container"  v-if="navList && showNavConten">
                <ul class="nav-list list-left">
                    <li v-for="item in navLeft" :key="item">
                        <router-link :to="link(item.url)" :data-component="item.page_template">{{ item.title }}</router-link>
                    </li>
                </ul>
                <ul class="nav-list list-right">
                    <li v-for="item in navRight" :key="item">
                        <router-link :to="link(item.url)" :data-component="item.page_template">{{ item.title }}</router-link>
                    </li>
                </ul>

                <div class="menu-footer">
                    <div class="img-box" v-if="getSettings && getSettings.blyz_add_group_links">
                        <router-link :to="link(getSettings.blyz_add_group_links.blyz_link_shops)" class="img-box-container" data-component="template_map.php">
                            <img :src="getSettings.blyz_add_group_links.blyz_img_shops.sizes.medium_large" alt="фото">
                            <p class="title">{{getSettings.blyz_add_group_links.blyz_text_btn_shops}}</p>
                        </router-link>
                    </div>
                <div class="img-box" v-if="getSettings && getSettings.blyz_add_group_links">
                        <router-link :to="link(getSettings.blyz_add_group_links.blyz_link_papper)" class="img-box-container" data-component="template_paper.php">
                            <img :src="getSettings.blyz_add_group_links.blyz_img_paper.sizes.medium_large" alt="фото">
                            <p class="title">{{getSettings.blyz_add_group_links.blyz_text_papper_btn}}</p>
                        </router-link>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
  </Transition>

  </nav>
  
</template>
<script>
import { watch } from 'vue'; 
import { mapGetters } from "vuex";
export default {
    props: {
        navList: Object
    },
     data() {
        return {
            navLeft: null,
            navRight: null,
            showNav: false,
            showNavConten: false,
            leftBgActive: false,
            rightBgActive: false
        };
    },
    created() {
        this.createMegaMenu()
        watch(
            () => this.navList, // Access the route using this.$route
            (newVal) => {
                if(!newVal) return
                this.createMegaMenu()
        })

        watch(
            () => this.$route, // Access the route using this.$route
            (to, from) => {

                if(from != to) {
                    this.showNav = false
                    document.querySelector('body').style.overflow = 'hidden auto'
                }
            })

    },
    computed: {
    ...mapGetters([
            "getSettings"
        ]),
    },
    methods: {
        createMegaMenu(){

            if(this.navList && this.navList.length > 0) {

                const countItems = this.navList.length

                this.navLeft = this.navList.slice(0, Math.ceil(countItems/2))

                this.navRight = this.navList.slice(Math.ceil(countItems/2), countItems)

            }
        },
            link(url) {
                const baseUrl = window.location.origin + "/blyzenko/";

                const link = new URL(url, baseUrl);
                if (link.pathname) {
                    return link.pathname;
                }

                 return link;
            },

            activeMenu() {
                
                if(!this.showNav) {
                    this.showNav = true
                    document.querySelector('body').style.overflow = 'hidden hidden'

                    setTimeout(() => {
                        this.leftBgActive = true
                    }, 50);

                    setTimeout(() => {
                        this.rightBgActive = true
                    }, 100);

                    setTimeout(() => {
                        this.showNavConten = true
                    }, 200);

                } else {

                    this.showNavConten = false
                   

                    setTimeout(() => {
                         this.rightBgActive = false
                    }, 400);

                    setTimeout(() => {
                        this.leftBgActive = false
                    }, 500);

                    setTimeout(() => {
                     this.showNav = false
                      document.querySelector('body').style.overflow = 'hidden auto'
                    }, 700)
                }
            }
    },
}
</script>
<style lang="">
    
</style>