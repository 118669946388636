<template>
  <div class="map-wrap">
    <div class="page-header">
      <h1 v-if="page && page.title" class="title">{{ page.title.rendered }}</h1>
      <p class="color-text">Близько, бо Близенько;)</p>
    </div>
    <transition name="fade-left">
      <div class="map-filter" v-show="state.showFilter">
        <button class="btn close" @click.prevent="toggleFilter">
          <span class="icon">+</span>
        </button>
        <div>
          <p>
            <Multiselect
              v-if="state.cityList"
              v-model="state.selectedCity"
              :close-on-select="true"
              :options="state.cityList"
              placeholder="Населений пункт"
              track-by="name"
              label="name"
              noOptionsText="Список порожній"
              noResultsText="Такого міста немає"
              :searchable="true"
              @input="handleCity"
              @clear="handleCity"
            />
          </p>

          <select
            @change="handleDistr"
            v-if="
              state.districtsList &&
              state.districtsList.length &&
              state.selectedCity
            "
          >
            <option value="">Район</option>
            <option
              v-for="filter in state.districtsList"
              :key="filter"
              :value="filter.id"
            >
              {{ filter.name }}
            </option>
          </select>

          <p>
            <Multiselect
              v-if="state.shops"
              v-model="state.selectedShop"
              :close-on-select="true"
              :options="state.shops"
              placeholder="Вулиця"
              label="address"
              noResultsText="Нічого не знайдено"
              noOptionsText="Список порожній"
              :searchable="true"
              @select="handleStreet"
              
            >
            </Multiselect>
          </p>
        </div>
        <button
          class="btn clear-btn"
          @click="clearFilter"
          v-if="state.currentCity ||
        state.selectedShop ||
        state.selectedServices.length > 0"
        >
          Очистити фільтри
        </button>

        <div class="services" v-if="state.services">
          <p class="filter-titel">За послугами</p>
          <div class="filter-services-list row">
            <label
              v-for="service in state.services"
              :key="service"
              :for="service.id"
              class="container-checkbox"
            >
              <span>{{ service["name"] }}</span>
              <input
                type="checkbox"
                :id="service.id"
                :value="service.id"
                @change="handleFilterShopServices"
                ref="filterItems"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </transition>
    <GoogleMap
      v-if="mapKey.key"
      ref="mapRef"
      :api-key="mapKey.key"
      :zoom="state.zoomMap"
      :center="mapCenter"
      class="map"
      @ready="initMap"
    >
      <CustomControl position="BOTTOM_CENTER">
        <button class="custom-btn btn" @click="locateUser"><span>ЗНАЙТИ МЕНЕ</span><span class="icon"><svg width="40.233" height="43.113" viewBox="0 0 40.233 43.113">
  <path id="Path_21" data-name="Path 21" d="M11.22,0,22.44,37.074,11.22,19.759,0,37.074Z" transform="matrix(0.799, 0.602, -0.602, 0.799, 22.312, 0)" fill="#ffffff"/>
</svg></span></button>
      </CustomControl>

    </GoogleMap>

    <button class="btn black btn-icon" @click.prevent="toggleFilter">
      Відкрити фільтр <span class="icon">+</span>
    </button>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  computed,
  ref,
  watch,
  watchEffect,
  reactive,
} from "vue";
//import { useRoute } from "vue-router";
import { useStore } from "vuex";
import {
  GoogleMap,
  CustomControl,
} from "vue3-google-map";
import Multiselect from "@vueform/multiselect";
import MarkerClusterer from "@googlemaps/markerclustererplus";

export default defineComponent({
  components: {
    GoogleMap,
    CustomControl,
    Multiselect,
  },
  setup() {
    const store = useStore();
   //const route = useRoute();
    const InfoWindowRef = ref(true);
    const mapCenter = ref({ lat: 49.832689, lng: 24.0122355 });
    const filterItems = ref([]);
    const mapRef = ref(null);
    const defaultZoom = 9;
    const mapKey = reactive({
      key: null
    })

    const state = reactive({
      zoomMap: defaultZoom,
      markers: [],
      markerCluster: null,
      selectedCity: "",
      selectedDist: "",
      selectedShop: "",
      locations: [],
      shops: [],
      cityList: [],
      activeCityList: [],
      districtsList: [],
      currentCity: "",
      services: [],
      selectedServices: [],
      showClearBtn: false,
      cityValue: null,
      showInfoWindow: null,
      InfoWindowIndex: null,
      showFilter: false,
    });

    watch(InfoWindowRef, (v) => {
      alert("infowindow has been " + (v ? "opened" : "closed"));
    });

    const locations = computed(() => store.getters.getShopsLocation);
    const shops = computed(() => store.getters.getShops);
    const services = computed(() => store.getters.getServices);
    const districts = computed(() => store.getters.getDistricts);
    const page = computed(() =>
      store.getters.getPage ? store.getters.getPage[0] : null
    );
    const setting = computed(() => store.getters.getSettings ? store.getters.getSettings : null);

    watchEffect(() => {
      if (setting.value) {
        mapKey.key = setting.value.blyz_google_key || null;
      }
    });

    const mapStyle = [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#212121",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#212121",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "administrative.country",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#181818",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#1b1b1b",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#2c2c2c",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8a8a8a",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            color: "#373737",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#3c3c3c",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#42af2a",
          },
        ],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry",
        stylers: [
          {
            color: "#4e4e4e",
          },
        ],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#42af2a",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#ededed",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#000000",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#3d3d3d",
          },
        ],
      },
    ];

    function getShopMapInfo(index, dataValue) {
      return shops.value[index]
        ? shops.value[index]["acf_fields"][dataValue]
        : "";
    }

    function getShopServices(index) {
      return shops.value[index] ? shops.value[index]["services"] : "";
    }

    function getShopButton(index, type) {
      if (type === "url") {
        const link = shops.value[index]
          ? new URL(shops.value[index][type])
          : "";
        return link.pathname;
      } else {
        return shops.value[index]
          ? `https://www.google.com/maps/dir/?api=1&destination=${shops.value[index]["acf_fields"]["shop_map"]["lat"]},${shops.value[index]["acf_fields"]["shop_map"]["lng"]}`
          : "";
      }
    }

    const shopListFilter = () => {
      state.showClearBtn =
        state.currentCity ||
        state.selectedShop ||
        state.selectedServices.length > 0
          ? true
          : false;

      if (state.selectedServices.length > 0 && state.showClearBtn) {
        shopsServicesFilter(shops.value);
        return;
      }

      if (!state.selectedCity || (!state.selectedCity && !state.selectedDist)) {
        state.shops = shops.value;
        return;
      }

      filterShopsByLocation();
      cityListFilter();
      distListFilter();
    };

    function filterShopsByLocation(shopArray) {
      const array = shopArray ? state.shops : shops.value;
      state.shops = array.filter((shop) => {
        if (state.selectedDist) {
          return isShopHasDistr(shop.distric, Number(state.selectedDist));
        } else {
          return isShopHasDistr(shop.distric, Number(state.selectedCity), true);
        }
      });      
    }

    function cityListFilter(cityArray) {
      const city = cityArray
        ? districts.value.filter(
            (item) => !item.parent && cityArray.includes(item.id)
          )
        : districts.value.filter((city) => !city.parent);

      const shopsCityt = city.map((location) => {
        return {
          name: location.name,
          value: location.id,
        };
      });

      state.cityList = [...new Set(shopsCityt)];
    }

    function distListFilter(activeArray) {
      if (!state.currentCity) return;

      if (state.currentCity && state.selectedServices.length > 0) {
        const allShopDist = state.shops
          .flatMap((shop) => {
            return shop.distric.map((elem) => {
              return elem.term_id;
            });
          })
          .filter((elem) => elem);

        state.districtsList = districts.value.filter(
          (dist) =>
            Number(dist.parent) == state.currentCity.id &&
            allShopDist.includes(dist.id) &&
            dist.count
        );
      }

      if (state.currentCity && state.selectedServices.length === 0) {
        state.districtsList =
          activeArray && activeArray.length > 0
            ? districts.value.filter(
                (dist) =>
                  Number(dist.parent) == state.currentCity.id &&
                  activeArray.includes(dist.parent) &&
                  dist.count
              )
            : districts.value.filter(
                (dist) =>
                  Number(dist.parent) == state.currentCity.id && dist.count
              );
      }

      // state.districtsList =
      //   state.districtsList.length > 0 ? state.districtsList : null;
    }

    function shopsServicesFilter(shopsList) {
      if (state.selectedServices.length === 0) {
        shopListFilter();
        return;
      }

      const shopsArray = shopsList ? shopsList : shops.value;
      state.locations = [];
      state.shops = [];
      addMapMarkers(null)

      state.activeCityList = [];
      shopsArray.forEach((shop) => {
        if (
          shop.services &&
          hasSelectedServices(shop.services, state.selectedServices)
        ) {
          const shopData = shop.acf_fields.shop_map;
          shopData["shop_id"] = shop.id;
          state.locations.push(shopData);
          addMapMarkers(state.locations)
          
          state.shops.push(shop);
          shop.distric.every((dist) => {
            if (dist.parent) {
              const distData = districts.value.filter(
                (city) => city.id == dist.parent
              );
              return state.activeCityList.push(distData[0].id);
            } else {
              return state.activeCityList.push(dist.term_id);
            }
          });
        }
      });

      if (state.selectedCity) {
        distListFilter();
        filterShopsByLocation(state.shops);
      } else {
        distListFilter();
        cityListFilter(state.activeCityList);
      }
    }

    function isShopHasDistr(obj, value, city) {
      for (const key in obj) {
        if (obj[key].term_id === value || (city && obj[key].parent === value)) {
          return true;
        }
      }
      return false;
    }

    function locateUser() {
      if (navigator.geolocation) {
        mapCenter.value = { lat: 49.832689, lng: 24.0122355 };
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position && position.coords) {
              const { latitude, longitude } = position.coords;

              // Update map center to user's location
              mapCenter.value = {
                lat: latitude,
                lng: longitude,
              };

              // Set desired zoom level
              state.zoomMap = 15; // Adjust the zoom level as needed
            } else {
              console.error("Unable to retrieve current position");
            }
          },
          (error) => {
            console.error("Error getting current position:", error);
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    }

    const updateMapCenter = () => {

      if (!state.locations || state.locations.length === 0) {
        mapCenter.value = { lat: 0, lng: 0 };
      }
      const totalMarkers = state.locations.length;
      const averageLat =
        totalMarkers > 1
          ? state.locations.reduce((sum, marker) => sum + marker.lat, 0) /
            totalMarkers
          : state.locations.lat;
      const averageLng =
        totalMarkers > 1
          ? state.locations.reduce((sum, marker) => sum + marker.lng, 0) /
            totalMarkers
          : state.locations.lng;

      mapCenter.value = {
        lat: Number(averageLat),
        lng: Number(averageLng),
      };
    };

    const handleCity = () => {
      const city = state.selectedCity;

      if (!city || city == "") {
        state.zoomMap = 8; 
        state.currentCity = ''
        return;
      }

      state.selectedDist = "";

      state.currentCity = districts.value.find(
        (dist) => dist.id === Number(city)
      );

      shopListFilter();
      distListFilter();

      if (state.currentCity) {
        const lat = state.currentCity.acf["dist-lan"];
        const lng = state.currentCity.acf["dist-lng"];
        
        state.zoomMap = 13;

        mapCenter.value = {
          lat: Number(lat),
          lng: Number(lng),
        };
        
      }
    };

    watch([() => state?.selectedCity], ([selectedCity]) => {
      if (!selectedCity) return;
      handleCity();
    });

    const handleDistr = (e) => {
      const distId = e.target.value;
      state.selectedDist = distId;

      if (!state.selectedDist) {
        state.zoomMap = 12;
        shopListFilter();
        return;
      }

      shopListFilter();

      const currentDist = districts.value.find(
        (dist) => dist.id == state.selectedDist
      );

      const lat = currentDist.acf["dist-lan"];
      const lng = currentDist.acf["dist-lng"];

      mapCenter.value = {
        lat: Number(lat),
        lng: Number(lng),
      };

      state.zoomMap = 13;
    };

    const handleStreet = () => {
      const shopId = state.selectedShop;

      if (!shopId) {
        state.zoomMap = 12;
        return;
      }
      const shop = shops.value.find((shop) => shop.id == shopId);

      const lat = shop.acf_fields.shop_map.lat;
      const lng = shop.acf_fields.shop_map.lng;

      mapCenter.value = {
        lat: Number(lat),
        lng: Number(lng),
      };

      state.zoomMap = shop.acf_fields.shop_map.zoom;

    };

    const handleFilterShopServices = (event) => {
      const isChecked = event.target.checked;
      if (!isChecked) {
        state.selectedServices = state.selectedServices.filter(
          (marker) => marker != event.target.value
        );
      } else {
        state.selectedServices.push(Number(event.target.value));
      }

      if (state.selectedServices.length === 0) {
        state.locations = locations.value;
        state.shops = shops.value;
        shopListFilter();
        cityListFilter();
        addMapMarkers(state.locations)
        return;
      }

      shopListFilter();
      //shopsServicesFilter()
      addMapMarkers(state.locations)
    };

    const clearFilter = () => {
      state.selectedCity = "";
      state.selectedDist = "";
      state.selectedShop = "";
      state.currentCity = "";
      state.selectedServices = [];

      //state.zoomMap = defaultZoom;

      filterItems.value.forEach((item) => {
        item.checked = false;
      });

      state.locations = locations.value;
      cityListFilter();
      distListFilter();
      shopListFilter(); 
      addMapMarkers(state.locations)     
    };

    function hasSelectedServices(
      itemArray,
      idArray,
      encounteredIds = new Set()
    ) {
      
      if (itemArray.length === 0 || idArray.length === 0) {
        return false;
      }

      for (const item of itemArray) {
        if (idArray.includes(item.term_id)) {
          encounteredIds.add(item.term_id);
        }

        if (encounteredIds.size === idArray.length) {
          return true;
        }
      }

      return false;
    }

    const clusterOptions = {
      styles: [
        {
          url: require("../../assets/images/m1.png"),
          height: 53,
          width: 53,
          textColor: "#000000",
          textSize: 12,
        },
        {
          url: require("../../assets/images/m1.png"),
          height: 56,
          width: 56,
          textColor: "#000000",
          textSize: 14,
        },
        {
          url: require("../../assets/images/m1.png"),
          height: 66,
          width: 66,
          textColor: "#000000",
          textSize: 16,
        },
      ],
    };
    function servicerItemList(list) {
      if(!list || !list.length) {
        
        return `<li class="services-list-none">Доступних сервісів в магазині немає</li>`
      }

      let listItems =`<li class="services-list-count">Сервісів в магазині: <span>${list.length}</span></li>`;

      list.forEach(item => {
        if(item.show_on_map ) {
          listItems += `<li class="services-list-item">${item.term_name}</li>`
        }
        
      })

      return listItems
    }

    function InfoWindowImage(index) {      
       const link = shops.value[index]
        ? shops.value[index]["acf_fields"].shop_image
        : null;

        if(!link) return ''

      return `<div class="shop-image">
            <img
              src="${link.sizes.medium}"
              class="img-fluid rounded-top"
              alt=""
            />
          </div>`

    }

     function createSvgMarker(color) {
          return {
            path: "M17.133,12.5C7.671,12.5,0,20.487,0,30.334c0,16.12,19.108,23.234,13.708,35.73,8.767-6.058,20.559-23.283,20.559-35.73C34.267,20.487,26.6,12.5,17.133,12.5Zm0,22.034a6.959,6.959,0,1,1,6.854-6.958A6.906,6.906,0,0,1,17.133,34.537Z",
            fillColor: color,
            fillOpacity: 1,
            strokeWeight: 0,
            rotation: 0,
            scale: 1,
            anchor: new mapRef.value.api.Point(0, 20),
          };
        }

        onBeforeUnmount(() => {
          if (mapRef.value) {
            clearMarkers()
          }
    });
    function addMapMarkers(locations) {
      let currentInfoWindow = null;
      let currentMarker = null;


      if (mapRef.value?.ready && !mapRef.value.ready) return;

      if(!mapRef.value && !mapRef.value?.map) return
        const map = mapRef.value.map;

        if(state.markerCluster) {
          clearMarkers()
        }

        if(!locations) return

        const bounds = new mapRef.value.api.LatLngBounds();
        state.markers = locations.map((location, index) => {
          const lat = Number(location.lat); // Ensure lat is a number
          const lng = Number(location.lng); // Ensure lng is a number

          const highlightedColor = "#42af2a"; // Default color for markers
          const defaultColor = "#FFFFFF"; // Highlight color for the active marker

          const latLng = new mapRef.value.api.LatLng(lat, lng);

          const marker = new mapRef.value.api.Marker({
            position: latLng,
            icon: createSvgMarker(defaultColor),
          });

          if(!state.currentCity) {
            bounds.extend(marker.getPosition());
          }

           const infoWindow = new mapRef.value.api.InfoWindow({
        content: `<div class="infowindow"><div id="content">
        ${InfoWindowImage(index)}

          <div class="shop-info">
            <p class="address">
              <strong>${location.shop_adress}</strong>
            </p>
            <p class="shop-time">
              <time
                datetime="${getShopMapInfo(index, 'shop_start')}"
                >${getShopMapInfo(index, 'shop_start')}</time
              >-
              <time
                datetime="
                  ${getShopMapInfo(index, 'shop_time_end')}
                "
                >
                   ${getShopMapInfo(index, 'shop_time_end')}
                </time
              >
            </p>
            
            <ul class="services-list">
              ${servicerItemList(location.services)}
             
            </ul>
          </div>
          <div class="buttons-wrap">
            <a
              href="${getShopButton(index, 'url')}"
              class="btn green"
              >Про магазин</a
            >
            <a
              href="${getShopButton(index, 'button')}"
              class="btn dark-green"
              target="__blank"
              >Маршрут сюди</a
            >
          </div>
        </div></div>`,
      });

      marker.addListener("click", () => {        
        if (currentInfoWindow) {
          currentInfoWindow.close(); 
        }

        if (currentMarker) {
          currentMarker.setIcon(createSvgMarker(defaultColor)); // Reset the color of the previous marker
        }

        infoWindow.open(map, marker); 
        marker.setIcon(createSvgMarker(highlightedColor));

        currentInfoWindow = infoWindow; 
        currentMarker = marker;

      });

          return marker;
        });

        for (let i = 0; i < state.markers.length; i++) {
          state.markers[i].setMap(mapRef.value.map);
        }

        if (state.markerCluster) {
            state.markerCluster.clearMarkers(); // Clear the previous marker cluster
          }

        state.markerCluster = new MarkerClusterer(
          mapRef.value.map,
          state.markers,
          clusterOptions
        );

        if(!state.currentCity) {
          map.fitBounds(bounds);
       //   console.log(bounds)
        }

        if(state.currentCity) {
          
          const lat = state.currentCity.acf["dist-lan"];
          const lng = state.currentCity.acf["dist-lng"];
            
            state.zoomMap = 13;

            mapCenter.value = {
              lat: Number(lat),
              lng: Number(lng),
            };
        }
        
    }



    function clearMarkers() {
       for (let i = 0; i < state.markers.length; i++) {
          state.markers[i].setMap(null);
        }
       // Remove markers from the map
      state.markers = []; // Clear the markers array
    }


    async function initMap() {

      await store.dispatch("fetchShops").then(() => {
        shopListFilter();

        state.locations = locations.value;

        addMapMarkers(state.locations)
       // updateMapCenter(locations);
       
      });
    }

    watch(
      () => mapRef.value?.ready,
      (ready) => {
        if (!ready) return;

        initMap();
      }
    );

    onMounted(() => {
      // store.dispatch('fetchPage', route.meta?.slug)

      // store.dispatch("fetchShops").then(() => {

      //     updateMapCenter(locations);
      //     shopListFilter();

      //     state.locations = locations.value;
      // })

      store.dispatch("fetchShopTaxonomy", "shop-services").then(() => {
        state.services = services.value;
      });

      store.dispatch("fetchShopTaxonomy", "shop-district").then(() => {
        state.districtsList = districts.value;

        cityListFilter();
      });

      watch(
        () => mapRef.value?.ready,
        (ready) => {
          if (!ready) return;
          const map = mapRef.value.map; // Access the Google Maps API instance
          map.setOptions({ styles: mapStyle });
        }
      );

      state.showFilter = window.innerWidth > 820 ? true : false;
    });

    const toggleInfoWindow = (marker, index) => {
      state.showInfoWindow =
        marker && state.showInfoWindow == marker ? null : marker;
      state.InfoWindowIndex = state.showInfoWindow ? index : null;
    };

    const toggleFilter = () => {
      state.showFilter = !state.showFilter;
      document.querySelector('html').style.overflow = state.showFilter ? 'hidden' : 'hidden auto'
    };

    return {
      state,
      page,
      locations,
      mapKey,
      getShopMapInfo,
      getShopServices,
      getShopButton,
      handleStreet,
      handleCity,
      handleDistr,
      mapCenter,
      handleFilterShopServices,
      clearFilter,
      filterItems,
      locateUser,
      CustomControl,
      mapRef,
      toggleInfoWindow,
      initMap,
      toggleFilter,
    };
  },
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
