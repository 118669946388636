import apiClient from '@/axios';

export const posts = {
    state: () => ({
        posts: [],
        errors: []
    }),

    actions: {
        async fetchPosts({ commit }:any) {
            try {
                const response = await apiClient.get('/posts');

                commit('setPosts', response)
                commit('setPostsError', null)

            }  catch (error) {
                commit('setPostsError', error)
            }        
              
          },
    },
    mutations: {
        setPosts(state:any, posts:any) {
            if(!posts) {
                state.posts = []
                return
            }
            state.posts = posts.data
        },

        setPostsError(state:any, error:any) {
            if(!error) {
                state.errors = []
            }

            state.errors = error
        }
    },
    getters: {
        getPosts(state:any) {
            return state.posts
        }
    }
}
