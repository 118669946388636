import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "post" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.post)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.post.content)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: _ctx.post.content.rendered
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}