<template lang="">
   <section class="FeedbackSection">
     <div class="container">
        <h1 class="section-title">Залишити звернення</h1>
        <FeedbackFormVue/>
    </div>
   </section>
</template>
<script>
import FeedbackFormVue from '../global/FeedbackForm.vue'

export default {
    components: {
        FeedbackFormVue
    }
}
</script>
<style lang="scss">
    .FeedbackSection {
        padding-top: 20px;
        .section-title {
            text-align: center;
        }
    }
</style>