<template lang="">
    <div v-if="page && pageFields" class="sales-page">
            <section class="page-header container">
                <div class="row">
                    <div class="col"><h1 class="section-title">{{page.title}}</h1></div>
                    <div class="col"><router-link 
                        v-if="getSettings && getSettings.blyz_add_group_links && getSettings.blyz_add_group_links.blyz_link_papper"
                        :to="link(getSettings.blyz_add_group_links.blyz_link_papper)"
                        class="btn gradient"
                    >
                    {{getSettings.blyz_add_group_links.blyz_text_papper_btn}}
                    </router-link></div>
                </div>
                <div><p class="color-text" v-if="pageFields && pageFields.call_action">{{pageFields.call_action}}</p></div>
            </section>

            <section class="sales container" id="sales">
                <div class="sales">
                    <ul class="sales-list" v-if="pageFields && pageFields.sale" :class="{'gridFour': pageFields.sale.length == 4 ||  pageFields.sale.length == 2 }">
                        <li class="sale-item"
                            v-for="sale in pageFields.sale"
                            :key="sale"
                        >
                        <div class="sale-item-inner row">
                            <div class="col content">
                                <div class="time">
                                    з <time :datetime="sale.sale_period">{{saleDate(sale.sale_period)}}</time>
                                    до <time :datetime="sale.sale_period_to">{{saleDate(sale.sale_period_to)}}</time>
                                </div>
                                <h3 class="title">
                                    {{sale.name_product}}
                                </h3>
                                <p class="text">
                                    {{sale.producer}}
                                </p>
                            </div>
                            <div class="col">
                                <div class="image-wrap">
                                    <picture class="img">
                                        <img :src="sale.image_product.sizes.medium" alt="">
                                    </picture>
                                </div>
                                <div class="price-wrap">
                                    <p class="price">
                                        <span class="sale-price">{{sale.sale_price}}</span>
                                        <span class="discount">-{{calcDiscount(sale.product_price, sale.sale_price)}}%</span>
                                        <span class="default-price">{{sale.product_price}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="sale-item-footer">
                                <a :href="appLink()" class="btn" :target="target">Купити онлайн</a>
                            </div>
                        </div>
                    </li>
                    </ul>
                </div>
            </section>
            <section class="runing-time-section" v-if="pageFields && pageFields.sale">
                <RuningTime postiton="top" itemText_1="Акції" itemText_2="Раптово" itemText_3="Вигідні"/>
            </section>
            <section class="sales suddenly container" id="suddenly">
                <div class="sales">
                    
                    <ul class="sales-list" v-if="pageFields && pageFields.sale_suddenly">
                        <li class="sale-item"
                            v-for="sale in pageFields.sale_suddenly"
                            :key="sale"
                            :class="{'empty':!sale}"
                        >
                        <div class="sale-item-inner row" v-if="sale">

                            <div class="image-wrap">
                                <picture class="img" v-if="sale.image_product && sale.image_product.sizes">
                                    <img :src="sale.image_product.sizes.medium" alt="">
                                </picture>
                            </div>

                                <div class="time">
                                    з <time :datetime="sale.sale_period">{{saleDate(sale.sale_period)}}</time>
                                    до <time :datetime="sale.sale_period_to">{{saleDate(sale.sale_period_to)}}</time>
                                </div>
                                <h3 class="title">
                                    {{sale.name_product}}
                                </h3>
                                <p class="text">
                                    {{sale.producer}}
                                </p>
                            
                        
                                <div class="price-wrap">
                                    <p class="price">
                                        <span class="default-price">{{sale.product_price}}</span>
                                        <span class="discount">-{{calcDiscount(sale.product_price, sale.sale_price)}}%</span>
                                        <span class="sale-price">{{sale.sale_price}}</span>
                                    </p>
                                </div>
                            
                        </div>
                         <div class="sale-item-inner row empty" v-else>
                            <div class="image-wrap">
                                <picture class="img" v-if="getSettings.blyz_empty_sales && getSettings.blyz_empty_sales.sizes">
                                    <img :src="getSettings.blyz_empty_sales.sizes.large" alt="нема пропозицій">
                                </picture>
                            </div>
                            </div>
                    </li>
                    </ul>
                </div>
            </section>
            <section class="runing-time-section bottom" v-if="pageFields && pageFields.sale_suddenly">
                <RuningTime postiton="bottom" itemText_1="Акції" itemText_2="Раптово" itemText_3="Шалені знижки"/>
            </section>
            <section class="paper-section">
                <div class="container row paper-block">
                    <div class="col content">
                        <h2 class="title" v-if="pageFields && pageFields.sale_paper_title">
                            {{pageFields.sale_paper_title}}
                        </h2>
                        <p class="text" v-if="pageFields && pageFields.sale_paper_text">
                            {{pageFields.sale_paper_text}}
                        </p>
                    </div>
                    <div class="col">
                        <PromoPaper
                            :showTitle="false"
                        />
                    </div>
                </div>
            </section>
            <section class="sale-offers-section">
                <div class="container">
                    <Carousel v-bind="settingsSlider.seettings" :breakpoints="settingsSlider.breakpoints" class="section-shop-images" v-if="pageFields && pageFields.sale_offers">
                    <Slide v-for="slide in pageFields.sale_offers" :key="slide">
                      <div class="carousel__item">
                        <picture class="carousel__item_img" v-if="slide.foto_offers.sizes">
                          <source :srcset="slide.foto_offers.sizes.medium_large" media="(max-width: 520px)"  />
                          <img :src="slide.foto_offers.sizes.large" :alt="slide.title" />
                        </picture>
                      </div>
                    </Slide>
      
                  <template #addons>
                    <Navigation />
                    <Pagination />
                  </template>
                </Carousel>
                    
                </div>
                
            </section>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import RuningTime from '../global/RuningTime.vue'
import PromoPaper from '../global/PromoPaperBlock.vue'
import { Carousel, Navigation, Slide, Pagination  } from 'vue3-carousel'
export default {
    components: {
        RuningTime,
        PromoPaper,
        Carousel,
        Slide,
        Navigation,
        Pagination 
    },
    
    data() {
        return {
            lastScrollPosition: 0,
            page: null,
            pageFields :null,
            target: '_self',
            settingsSlider: {
                seettings: {
                    itemsToShow: 1.15,
                    wrapAround: true
                },

                breakpoints: {
                    // 700px and up
                    880: {
                        itemsToShow: 1.5,
                    },
                    // 1024 and up
                    1200: {
                        itemsToShow: 2,
                        snapAlign: 'start',
                        wrapAround: true,
                        autoplay: 5000,
                        transition: 500,
                        pauseAutoplayOnHover: true
                    },
                },
            }
        }
    },
    computed: {
        ...mapGetters(["getPage", "getSettings"]),
    },
    mounted() {
        this.lastScrollPosition = window.pageYOffset
        const pageSlug = this.$route.params?.pageSlug;
        this.loadPageContent(pageSlug)
        this.appLink()
    },
    methods: {
        ...mapActions(["fetchPageCustom"]),

        loadPageContent(slug) {
            this.fetchPageCustom(slug)
                .then(() => {
                    this.page = this.getPage;
                    this.pageFields = this.getPage.acf
                    this.countSalesSudenly()
                })
            },
        calcDiscount(defaultPrice, salePrice) {
            if(!defaultPrice) return

            return salePrice ? (100 - ((Number(salePrice) * 100)/Number(defaultPrice))).toFixed(0) : defaultPrice
        },

        saleDate(date) {
            const customDite = date.split('/')
            const dayNumber =  parseInt(customDite[0], 10);
            const monthNumber = parseInt(customDite[1], 10); 
            
            return `${dayNumber > 9 ? dayNumber : '0'+dayNumber}.${monthNumber > 9 ? monthNumber : '0'+monthNumber}`;
        },

        countSalesSudenly(){
            if(this.pageFields && !this.pageFields.sale_suddenly || this.pageFields && !this.pageFields.sale_suddenly.length) return 
            
            if(this.pageFields && this.pageFields.sale_suddenly && this.pageFields.sale_suddenly.length%3) {
                 this.pageFields.sale_suddenly.every(item => {
                    if(this.pageFields.sale_suddenly.length%3) {
                        return this.pageFields.sale_suddenly.push('')
                    }
                })
            }

            if(this.pageFields.sale_suddenly.length <3 ) {
                this.countSalesSudenly()
            }

            return this.pageFields?.sale_suddenly
        },
        getMobileOperatingSystem() {
            let userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // iOS detection
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return 'iOS';
            }

            // Android detection
            if (/android/i.test(userAgent)) {
                return 'Android';
            }

            // Windows Phone detection
            if (/windows phone/i.test(userAgent)) {
                return 'Windows Phone';
            }

            return 'unknown';
        },
        appLink(){
            let os = this.getMobileOperatingSystem();

            if (os === 'iOS') {
                this.target = '_blank'
                return this.getSettings.blyz_link_appstore;
            } else if (os === 'Android' || os === 'Windows Phone') {
                this.target = '_blank'
                return this.getSettings.blyz_link_google;
            } else {
                this.target = '_self'
                return this.getSettings.group_shop && this.getSettings.group_shop.button_link ? this.link(this.getSettings.group_shop.button_link.url) : this.getSettings.blyz_link_google;
            }
        },
        link(url) {
            const baseUrl = window.location.origin + "/blyzenko/";

            const link = new URL(url, baseUrl);
            if (link.pathname) {
                let url =link.pathname + link.hash + link.search;
                
                return url;
            }

            return link;
            },
    },
}
</script>
<style lang="scss">
    @import '../../assets/scss/sales-page.scss';
</style>