import { watch, reactive } from 'vue';
<template>
    <div class="vue-responsive-videobg" :class="{myVideo : isAnimate}" :style="`max-height: ${videoHeight}; height: 100vh`"  v-if="
                    videoSrc || posterLink
                  ">
            <div class="video-wrapper">
              <video 
                  ref="videoBgRefs"
                  :poster="posterLink"
                  autoplay loop muted playsinline
                  :src="videoSrc"
                  >
                  <source :src="mobileSrc" type="video/mp4" media="screen and (max-device-width:960px)">
                  <source  :src="videoSrc" type="video/mp4" media="screen and (min-device-width:961px)">
              </video>
            </div>
          </div>
</template>
<script>
import { onMounted, watch, ref } from 'vue';

onMounted
export default {
    props: {
        videoSrc: String,
        posterLink: String,
        isAnimate: Boolean,
        mobileSrc: String,
        maxHeight: String,
        maxHeightMob: String
    },
    setup(props) {
      const videoBgRefs = ref(null);
      const videoHeight = ref(null)
      const heightVideo = props.maxHeight ? props.maxHeight : "900px"
      const heightVideoMob = props.maxHeightMob ? props.maxHeightMob : "540px"

    function playVideo() {
        if(videoBgRefs.value.paused) {
            videoBgRefs.value.muted = true;
            videoBgRefs.value.loop = true;
            videoBgRefs.value.play()
          }
      }

      watch(videoBgRefs, (newX) => {
        if(newX) {
         setTimeout(() => {
          playVideo()
         }, 300);

         videoHeight.value = window.innerWidth > 640 ? heightVideo : heightVideoMob
        }
          
      })

      return {
        videoBgRefs,
        videoHeight
      }
    }
}
</script>
<style lang="">
    
</style>