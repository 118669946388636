import apiClient from '@/axios';

export const shops = {
    state: () => ({
        shops: [],
        shopsCRM: [],
        singleShop: [],
        locations: [],
        services: [],
        districts: [],
        errors: []
    }),

    actions: {
        async fetchShops({ commit }:any) {
            try {
                const response = await apiClient.get('/shops-list');
                
                commit('setShops', response)
                commit('setShopLocation', response)
                commit('setShopsError', null)

            }  catch (error) {
                commit('setShopsError', error)
            }        
              
          },
        async fetchShopsCRM({ commit }:any) {
            try {
                const response = await apiClient.get('/shops');
                commit('setShopsCRM', response)
                
                commit('setShopsError', null)

            }  catch (error) {
                commit('setShopsError', error)
            }        
              
          },

          async fetchSingleShop({ commit }:any, slug:string) {
            try {
                const response = await apiClient.get(`/shops/${slug}`);

                commit('setSingleShop', response)
                commit('setPostError', null)

            }  catch (error) {
                commit('setPostError', error)
            }        
              
          },

          async fetchShopTaxonomy({ commit }:any, taxonomy:string) {
            
            if(!taxonomy) return

            try {
                const response = await apiClient.get(`/${taxonomy}`, {
                    params: {
                      per_page: 100, // Adjust per_page as needed
                    },
                  });

                if(taxonomy == 'shop-services') {
                    commit('setTaxonommyServices', {'result': response })
                } 
                
                if(taxonomy == 'shop-district') {
                    commit('setTaxonommyDistrs', {'result': response })
                } 

                commit('setShopsError', null)
            } catch (error) {
                commit('setShopsError', error)
            }
          }
    },
    mutations: {
        setShops(state:any, shops:any) {
            if(!shops) {
                state.shops = []
                return
            }
            state.shops = shops.data;
        },
        setShopsCRM(state:any, shops:any) {

            if(!shops) {
                state.shopsCRM = []
                return
            }

            state.shopsCRM = shops.data;
        },

        setShopLocation(state: any, shops:any) {
            if(!shops) {
                state.locations = []
                return
            }            

            state.locations = []
            shops.data.every( (shop: any ) => {
                const shopData = shop.acf_fields.shop_map;
                shopData['shop_adress'] = shop.acf_fields.shop_adress;
                shopData['distric'] = shop.distric[0];
                shopData['services'] = shop.services;
                return state.locations.push(shopData);
            })

        },

        setSingleShop(state: any, shop:any) {
            if(!shop) {
                state.singleShop = []
                return
            }

            state.singleShop = shop.data;

        },

        setTaxonommyServices(state:any, result:any) {
            
            state.services = result ? result.result.data : []

        },

        setTaxonommyDistrs(state:any, result:any) {
            
            state.districts = result ? result.result.data : []

        },


        setShopsError(state:any, error:any) {
            if(!error) {
                state.errors = []
            }

            state.errors = error
        }
    },
    getters: {
        getShops(state:any) {
            return state.shops
        },
        getShopsCRM(state:any) {
            return state.shopsCRM
        },
        getSingleShop(state:any) {
            return state.singleShop
        },
        getServices(state:any) {
            return state.services
        },
        getDistricts(state:any) {
            return state.districts
        },
        getShopsLocation(state:any) {
            return state.locations
        }
    }
}
