<template lang="">
    <div class="counter-block" v-if="getSettings && getSettings.counter_list">
        <div
            class="count-item"
            v-for="item in getSettings.counter_list"
            :key="item"
        >
            <p class="count" ref="counter" :data-target="item.count_number">0</p>
            <p class="title">{{item.title}}</p>

        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ref, watch } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
    computed: {
        ...mapGetters([
            "getSettings",
        ]),
    },
   setup() {

    const counter = ref([])
    const animateCount = (counts) => {
       
      counts.forEach((count) => {
        var target = { val: 0 };
        gsap.to(target, {
          val: parseFloat(count.getAttribute('data-target')),
          duration: 2,
          onUpdate: function () {
            count.innerText = Math.ceil(target.val);
          },
        });
      });
    };

    
        watch(counter?.value, (value) => {
            if(value) {
                 const counts = value;
                ScrollTrigger.create({
                    trigger: counts, 
                    start: 'top 80%', 
                    onEnter: () => {
                    animateCount(counts); 
                    },
                });
            }
        })

    return {
        counter
    }
  },
}
</script>
<style lang="scss">
    @import "../../assets/scss/components/counter.scss";

</style>