<template lang="">
    <footer class="site-footer">
        <ButtomFooterVue/>
        <div class="footer-inner container">
            <div class="row top-bar">
                <div class="col contacts">
                    <h3 class="title">Гаряча лінія</h3>
                    <ul class="contact-list list" v-if="getSettings">
                        <li class="item" v-if="getSettings.blyz_email"><a :href="`mailto:${getSettings.blyz_email}`">{{getSettings.blyz_email}}</a></li>
                        <li class="item" v-if="getSettings.blyz_phone"><a :href="`tel:${getSettings.blyz_phone}`">{{getSettings.blyz_phone}}</a></li>
                        <li class="item" v-if="getSettings.work_houers">{{getSettings.work_houers.from}} - {{getSettings.work_houers.to}}</li>
                    </ul>
                </div>

                <div class="col address">
                    <h3 class="title">Центральний офіс</h3>
                    <p class="address-item" v-if="getSettings && getSettings.blyz_address" v-html="getSettings.blyz_address"></p>
                </div>

                <div class="col social-media">
                    <h3 class="title">Соціальні мережі</h3>
                    <ul class="social-media-list list" v-if="getSettings && getSettings.blyz_medialinks">
                        <li class="item" 
                        v-for="link in getSettings.blyz_medialinks"
                        :key="link"
                        >
                        <a :href="link.url" target="_blank" class="row">
                            <span>{{link.name}}</span>
                            <span class="icon">
                                <svg width="13.876" height="13.787" viewBox="0 0 13.876 13.787">
                                    <g id="Group_65" data-name="Group 65" transform="translate(-1339.748 -7500.045) rotate(1)">
                                        <line id="Line_11" data-name="Line 11" y1="10.19" x2="10.824" transform="translate(1472.088 7477.464)" fill="none" stroke="#fff" stroke-linecap="square" stroke-width="2"/>
                                        <line id="Line_12" data-name="Line 12" x2="10.755" transform="translate(1472.461 7476.504)" fill="none" stroke="#fff" stroke-linecap="square" stroke-width="2"/>
                                        <line id="Line_13" data-name="Line 13" y1="10.755" transform="translate(1483.31 7476.502)" fill="none" stroke="#fff" stroke-linecap="square" stroke-width="2"/>
                                    </g>
                                </svg>
                            </span>
                        </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="row action-block">
            <div class="col button-wrap">
                <a :href="link(getSettings.blyz_call_back_link.url)" class="btn" v-if="getSettings && getSettings.blyz_call_back_text">{{getSettings.blyz_call_back_text}}</a>
            </div>
            <div class="col">
                <p class="color-text"
                v-if="getSettings && getSettings.blyz_call_action"
                >
                    {{getSettings.blyz_call_action}}
                </p>
            </div>
        </div>
        <nav class="footer-nav">
            <ul class="footer-nav-list"
                 v-if="nav"
            >
                <li class="footer-nav-item"
                v-for="item in nav"
                :key="item"
                >
                    <a :href="link(item.url)">{{item.title}}</a>
                </li>
            </ul>
        </nav>
        <p class="copyright"
        v-if="getSettings && getSettings.blyz_copiright"
        >
            {{getSettings.blyz_copiright}}
        </p>
        </div>
        
    </footer>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import ButtomFooterVue from './ButtomFooter.vue';

export default {
    components: {
        ButtomFooterVue
    },
    data() {
        return {
            nav: null
        }
    },
    computed: {
        ...mapGetters([
            "getSettings",
            "getNav"
            ]),
    },
    mounted() {
        this.getFooterNav();
    },
    methods: {
        ...mapActions([
            "fetchNavigation",
        ]),

        getFooterNav() {
            const navSlug = "footer-menu";
            this.fetchNavigation({slug:navSlug, type:'footer'}).then(() => {
                
                this.nav = this.getNav.footer;
                return;
            });
        },

        link(url) {
            const baseUrl = window.location.origin + "/blyzenko/";

            const link = new URL(url, baseUrl);
            if (link.pathname) {
                const url = link.hash ? link.pathname + link.hash : link.pathname;
                return url;
            }

            return link;
            },
    },
}
</script>
<style lang="">
    
</style>