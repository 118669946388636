import apiClient from '@/axios';

export const page = {
    state: () => ({
        page: null,
        errors: []
    }),

    actions: {
        async fetchPage({ commit, state }:any, slug:string) {
            try {
                state.page = null
                const response = await apiClient.get('/pages?slug=' + slug);

                commit('setPage', response)
                commit('setPageError', null)

            }  catch (error) {
                commit('setPageError', error)
            }        
              
          },

          async fetchPageCustom({ commit, state }:any, slug:string) {
            try {
                state.page = null
                const response = await apiClient.get('/blyzenko-page/' + slug);

                commit('setPage', response)
                commit('setPageError', null)

            }  catch (error) {
                commit('setPageError', error)
            }        
              
          },

        async fetchHomePage({ commit }:any) {
            try {
                const response = await apiClient.get('/home');

                commit('setPage', response)
                commit('setPageError', null)

            }  catch (error) {
                commit('setPageError', error)
            }        
              
          },
    },
    mutations: {
        setPage(state:any, post:any) {
            if(!post) {
                state.posts = null
                return
            }
            
            state.page = post.data

        },

        setPageError(state:any, error:any) {
            if(!error) {
                state.errors = []
            }

            state.errors = error
        }
    },
    getters: {
        getPage(state:any) {
            return state.page
        }
    }
}
