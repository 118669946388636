<template>
<div>
    <div class="single-shop container"  v-if="shop">
    <section class="row shop-info-block">
     <div class="col shop-info">
      <h1 v-if="shop.title" class="title">
          <span v-html="shop.title"></span>
        </h1>

        <address class="address">
          {{shopFields.shop_map.city}}, {{shopFields.shop_adress}}
        </address>
       

        <div class="work-time">
          <time v-if="shopFields && shopFields.shop_start" :datetime="shopFields.shop_start">{{shopFields.shop_start}}</time>-<time v-if="shopFields && shopFields.shop_time_end" :datetime="shopFields.shop_time_end">{{shopFields.shop_time_end}}</time>
        </div>
     </div>
     <div class="col shop-map">
      <div class="map-shop-wrap" v-if="shopFields">
          <GoogleMap
            :mapInfo="shopFields"
            :services="shop.services"
          />
      </div>
     </div>
     </section>
     <section class="services" id="services" v-if="shop.services">
      <h2>Послуги в магазині</h2>
      <ul class="services-list">
        <li class="services-item"
          v-for="service in shop.services"
          :key="service"
        >
            <div class="services-item-wrap">
              <div class="image-wrap">
                <img :src="service.icon" :alt="`фото послуги ${service.term_name}`" class="service-image">
              </div>
              <p class="service-name">
                {{service.term_name}}
              </p>
            </div>
        </li>
      </ul>
     </section>

        <section class="section-title" v-if="shopFields && shopFields.shop_gallery">
              <div class="conainer">
                  <h2>Фото магазину</h2>
              </div>
        </section>
          
            <Carousel v-bind="settingsSlider.seettings" :breakpoints="settingsSlider.breakpoints" class="section-shop-images" v-if="shopFields && shopFields.shop_gallery">
              <Slide v-for="slide in shopFields.shop_gallery" :key="slide">
                <div class="carousel__item">
                  <picture class="carousel__item_img">
                    <source :srcset="slide.sizes.medium_large" media="(max-width: 520px)"  />
                    <img :src="slide.sizes.large" :alt="slide.title" />
                  </picture>
                </div>
              </Slide>

            <template #addons>
              <Navigation />
              <Pagination />
            </template>
          </Carousel>
          
        

     <section class="app-block" v-if="getSettings && getSettings.group_shop">
      <div class="container">
        <div class="row">
        <div class="col content">
            <h2 v-if="getSettings.group_shop.title" class="title">{{getSettings.group_shop.title}}</h2>
            <p class="short-descriprion" v-if="getSettings.group_shop.short_description">
              {{getSettings.group_shop.short_description}}
            </p>
           
           <div>
             <a :href="getSettings.group_shop.button_link.url" 
              v-if="getSettings.group_shop.button_link"
              class="btn">
                {{getSettings.group_shop.button_text}}
              </a>
           </div>
            
             <div class="button-block apps row">
              <a
                v-if="getSettings && getSettings.blyz_link_google"
                :href="getSettings.blyz_link_google"
                target="_blank"
                class="btn-google app-btn"
              >
                <span class="icon">
                  <svg
                    fill="#fff"
                    width="800px"
                    height="800px"
                    viewBox="0 0 512 512"
                  >
                    <title>ionicons-v5_logos</title>
                    <path
                      d="M48,59.49v393a4.33,4.33,0,0,0,7.37,3.07L260,256,55.37,56.42A4.33,4.33,0,0,0,48,59.49Z"
                    />
                    <path
                      d="M345.8,174,89.22,32.64l-.16-.09c-4.42-2.4-8.62,3.58-5,7.06L285.19,231.93Z"
                    />
                    <path
                      d="M84.08,472.39c-3.64,3.48.56,9.46,5,7.06l.16-.09L345.8,338l-60.61-57.95Z"
                    />
                    <path
                      d="M449.38,231l-71.65-39.46L310.36,256l67.37,64.43L449.38,281C468.87,270.23,468.87,241.77,449.38,231Z"
                    />
                  </svg>
                </span>
                <div class="text">
                  <span>завантажуй з</span>
                  <span class="name">Google Play</span>
                </div>
              </a>
              <a
                v-if="getSettings && getSettings.blyz_link_appstore"
                :href="getSettings.blyz_link_appstore"
                target="_blank"
                class="btn-appstore app-btn"
              >
                <span class="icon">
                  <svg
                    fill="#fff"
                    version="1.1"
                    width="800px"
                    height="800px"
                    viewBox="0 0 512 512"
                    xml:space="preserve"
                  >
                    <g id="7935ec95c421cee6d86eb22ecd114eed">
                      <path
                        style="display: inline"
                        d="M248.644,123.476c-5.45-29.71,8.598-60.285,25.516-80.89   c18.645-22.735,50.642-40.17,77.986-42.086c4.619,31.149-8.093,61.498-24.826,82.965   C309.37,106.527,278.508,124.411,248.644,123.476z M409.034,231.131c8.461-23.606,25.223-44.845,51.227-59.175   c-26.278-32.792-63.173-51.83-97.99-51.83c-46.065,0-65.542,21.947-97.538,21.947c-32.96,0-57.965-21.947-97.866-21.947   c-39.127,0-80.776,23.848-107.19,64.577c-9.712,15.055-16.291,33.758-19.879,54.59c-9.956,58.439,4.916,134.557,49.279,202.144   c21.57,32.796,50.321,69.737,87.881,70.059c33.459,0.327,42.951-21.392,88.246-21.616c45.362-0.258,53.959,21.841,87.372,21.522   c37.571-0.317,67.906-41.199,89.476-73.991c15.359-23.532,21.167-35.418,33.11-62.023   C414.435,352.487,389.459,285.571,409.034,231.131z"
                      ></path>
                    </g>
                  </svg>
                </span>
                <div class="text">
                  <span>завантажуй з</span>
                  <span class="name">App Store</span>
                </div>
              </a>
            </div>
        </div>
        <div class="col images">
          <VideoBg
              v-if="getSettings?.group_shop.show_video[0]"
              :videoSrc = "getSettings.group_shop.Video.url"
              :posterLink = "getSettings.group_shop.Video.icon"
              :isAnimate=false
              maxHeight="720px"
              class="app-block-video"
          />

            <picture class="img" v-if="getSettings && getSettings.group_shop && getSettings.group_shop.image && !getSettings.group_shop.show_video[0]">
              <source :srcset="getSettings.group_shop.image.sizes.medium_large" media="(max-width: 520px)"  />
              <img :src="getSettings.group_shop.image.sizes.large" alt="" />
            </picture>
        </div>
      </div>
      </div>
     </section>
   
  </div>
    
</div>


</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapGetters, mapActions } from 'vuex'
import GoogleMap from '../components/pages/SingleShopMap.vue'
import VideoBg from '@/components/global/VideoBg.vue';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Navigation, Slide, Pagination  } from 'vue3-carousel'

@Options({
  components: {
    GoogleMap,
    Carousel,
    Slide,
    Navigation,
    Pagination,
    VideoBg
  },

  computed: {
    ...mapGetters([
    'getSingleShop',
    'getSettings'
    ]),
  },

  methods: {
    ...mapActions([
      'fetchSingleShop'
    ])
  }

})

export default class PageView extends Vue {
    $store!: any;

    $route!: any;

    shop:any = null
    shopFields:any = null
    shopServices:any = []
    settingsSlider:object = {
      seettings: {
        itemsToShow: 1.25,
        snapAlign: 'center',
        wrapAround: true
      },

      breakpoints: {
        // 700px and up
        880: {
          itemsToShow: 1.5,
          snapAlign: 'center',
        },
        // 1024 and up
        1200: {
          itemsToShow: 1.8,
          snapAlign: 'center',
          wrapAround: true
        },
      },
    }

    fetchSingleShop!: (pageSlug: string) => Promise<void>
    getSingleShop!: any

   mounted() {
    
    const pageSlug = this.$route.params?.shopSlug;
    // Use the mapped getter and action.
    this.fetchSingleShop(pageSlug).then(() => {
      this.shop = this.getSingleShop
      this.shopFields = this.getSingleShop.acf_fields
      this.shopServices = this.getSingleShop.services
      return
    })
  }
}

</script>

<style lang="scss">
  @import "../assets/scss/single-shop.scss";
</style>