import apiClient from '@/axios';

export const vacancies = {
    state: () => ({
        vacancies: [],
        singleVacancy: [],
        locations: [],
        workDate: [],
        workType: [],
        cityList: [],
        cityRegions: [],
        departments: [],
        errors: []
    }),

    actions: {

        async fetchVacances({ commit }:any) {
            try {
                const response = await apiClient.get('/vacancies');

                commit('setVacances', response)
                //commit('setLocation', response)
         //       commit('setVacancysError', null)

            }  catch (error) {
              //  commit('setVacancysError', error)
            }        
              
          },

          async fetchSingleVacancy({ commit }:any, slug:string) {
            try {
                const response = await apiClient.get(`/vacancies/${slug}`);

                commit('setSingleVacancy', response)
                commit('setVacancyError', null)

            }  catch (error) {
                commit('setVacancyError', error)
            }        
              
          },
    },

    mutations: {
        setVacances(state:any, data:any) {
            if(!data) {
                return state.vacancies = []
            }
            
           
    
            state.vacancies = data.data.vacances_list

            const cityListArray = Object.values(data.data.city_list)

          
            const city = cityListArray.filter((item:any) => !item.parent);
           
    
    
            const region = cityListArray.filter((item:any) => item.parent);

    
            state.cityList = city.map((item:any) => ({
                name: item.name,
                value: item.term_id,
            }));
    
            state.cityRegions = region.map((item:any) => ({
                name: item.name,
                value: item.term_id,
                parent: item.parent
            }));
    
            state.workDate = data.data.all_work_date;
            state.workType = data.data.type_vacancy;
            state.departments = data.data.all_departments;

        },

        setSingleVacancy( state: any, data:any) {

            if(!data) {
                return state.singleVacancy = []
            }

            state.singleVacancy = data.data
        },

        setVacancyError(state: any, errors:any) {
            if(!errors) {
                return
            }

            console.error(errors)
        }
    },

    getters: {
        getVacances(state:any) {
            return state.vacancies
        },
        getSingleVacancy(state:any) {
            return state.singleVacancy
        },
        getVacancesWorkDate(state:any) {
            return state.workDate
        },
        getVacancesWorkType(state:any) {
            return state.workType
        },
        getVacancesDepartments(state:any) {
            return state.departments
        },
        getVacancesCityList(state:any) {
            return state.cityList
        },
        getVacancesCityRegion(state:any) {
            return state.cityRegions
        },
    }
}