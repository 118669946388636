<template lang="">
  <div class="count-item" ref="countItem">
    <p class="count">{{ counter }} <span v-if="prefix">{{prefix}}</span></p>
    <p class="title">{{ title }}</p>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  props: {
    count: [Number, String],
    prefix: String,
    title: String,
  },
  setup(props) {
    const counter = ref(0);
    const countItem = ref(null);

    const animateCount = (count) => {
      gsap.to(counter, {
        value: parseFloat(count),
        duration: 2,
        onUpdate: function () {
          counter.value = Math.ceil(counter.value);
        },
      });
    };

    onMounted(() => {
      watch(
        () => props.count, // Watcher source as a function
        (newCount) => {
          if (newCount !== undefined) {
            ScrollTrigger.create({
              trigger: countItem.value,
              start: 'top 80%',
              onEnter: () => {
                animateCount(newCount);
              },
            });
          }
        },
        { immediate: true }
      );
    });

    return {
      counter,
      countItem,
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/components/counter.scss";
</style>
